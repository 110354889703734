import { Fragment } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import RootLayout from './components/layout';
import OnlyHeadersLayout from './components/onlyheaderslayout';

import ShopDetails from './components/shop/shopdetails';
import CourseDetails from './pages/jebs/course/CourseDetails';

import Home from './pages/jebs/home';
import SignIn from './pages/sign/signin';
import LoginId from './pages/sign/login/LoginId';
import LoginPassword from './pages/sign/login/LoginPassword';

import Shop from './pages/jebs/shop/shop';
import Courses from './pages/jebs/course/courses';

import './App.css';
import StoryMain from './pages/jebs/story/StoryMain';
import ClassManagement from './pages/jebs/learning/ClassManagement';
import Purchase from './pages/jebs/purchase/purchase';
import PurchaseStaging from './pages/commerce/purchase/staging';
import OnlyBodyLayout from './components/onlybodylayout';
import Cart from './pages/cart/cart';
import CompleteBranch from './pages/commerce/purchase/complete/completebranch';
import ReviewMain from './pages/jebs/review/ReviewMain';
import ReviewDetail from './pages/jebs/review/ReviewDetail';
import Modal from './components/modal/Modal';
import ChannelTalk from './components/ChannelTalk';
import SignupPage from './pages/sign/signup/SignupPage';
import HFsample from './components/common/input/HFsample';
import OauthSuccess from './pages/sign/login/OauthSuccess';
import CourseCategory from './pages/jebs/course/CourseCategory';

import RouteMypage from './pages/mypage/RouteMypage';
import RouteStudent from './pages/student/RouteStudent';
import RouteLms from './pages/lms/RouteLms';
import RouteLearning from './pages/jebs/learning/RouteLearning';
import ContentsView from './pages/ContentsView';
import WindowClose from './pages/WindowClose';
import VimeoContentView from './pages/lms/classmgmt/childpages/VimeoContentsView';

function App() {
  return (
    <Fragment>
      <ChannelTalk />
      <Modal />
      <BrowserRouter>
        <Routes>
          {/* HOME */}
          <Route element={<RootLayout />}>
            <Route index path="/" element={<Home />} />
            <Route path="/story" element={<StoryMain />} />
            <Route path="/review" element={<ReviewMain />} />
            <Route
              path="/review/details/:reviewId"
              element={<ReviewDetail />}
            />
            <Route path="/sample" element={<HFsample />} />
          </Route>

          {/* 코스찾기 */}
          <Route element={<OnlyHeadersLayout />}>
            <Route index path="/courses" element={<Courses />} />
            <Route
              path="/courses/:productSubType"
              element={<CourseCategory />}
            />
            <Route
              path="/courses/:productSubType/details/:homeschoolId"
              element={<CourseDetails />}
            />
          </Route>

          {/* 내 학습 */}
          <Route path="/learning/*" element={<RouteLearning />} />

          {/* 클래스 관리 */}
          <Route element={<OnlyHeadersLayout />}>
            <Route
              index
              path="/ClassManagement"
              element={<ClassManagement />}
            />
          </Route>

          {/* SHOP */}
          <Route element={<OnlyHeadersLayout />}>
            <Route index path="/shop" element={<Shop />} />
            <Route path="shop/details/:shopId" element={<ShopDetails />} />
          </Route>

          {/* 결제화면 */}
          <Route element={<OnlyHeadersLayout />}>
            <Route index path="/purchase" element={<Purchase />} />
            <Route index path="/cart" element={<Cart />} />
          </Route>
          <Route element={<OnlyBodyLayout />}>
            {/* PG사 콜백 페이지 */}
            <Route
              index
              path="/commerce/purchase/staging"
              element={<PurchaseStaging />}
            />
          </Route>
          <Route element={<RootLayout />}>
            {/* 완료 분기 페이지 */}
            <Route
              index
              path="/purchase/complete/:orderMasterId"
              element={<CompleteBranch />}
            />
            {/* 카드결제 완료 페이지 */}
            {/* <Route path="/purchase/complete/creditcard/:orderMasterId" element={<CreditCard />} /> */}
            {/* 무통장입급 페이지 */}
            {/* <Route path="/purchase/waiting/deposit/:orderMasterId" element={<Deposit />} /> */}
          </Route>

          {/* 로그인 */}
          <Route element={<OnlyHeadersLayout />}>
            <Route index path="/signin" element={<SignIn />} />
            <Route path="signin/id" element={<LoginId />} />
            <Route path="signin/password" element={<LoginPassword />} />
          </Route>

          {/* 카카오톡 로그인 리턴 페이지 */}
          <Route path="/oauth/success" element={<OauthSuccess />} />

          {/* 회원가입 */}
          <Route element={<RootLayout />}>
            <Route index path="/signup" element={<SignupPage />} />
          </Route>

          {/* 내 정보 */}
          <Route element={<OnlyHeadersLayout />}>
            <Route index path="/mypage/*" element={<RouteMypage />} />
          </Route>

          {/* 학생 페이지 */}
          <Route index path="/student/*" element={<RouteStudent />} />

          {/* LMS */}
          <Route index path="/lms/:classId/*" element={<RouteLms />} />

          {/* Contents */}
          <Route path="/contents" element={<ContentsView />} />

          {/* Vimeo Content View */}
          <Route path="/vimeo/:contentId" element={<VimeoContentView />} />

          {/* 젭스플 종료후 처리 */}
          <Route path="/close" element={<WindowClose />} />

          {/* 비정상 접근시 홈으로 */}
          <Route path="/*" element={<Navigate to={'/'} />} />
        </Routes>
      </BrowserRouter>
      {/* 테일윈드는 동적 사용되는 컬러의경우 돔생성 되기전에 클레스 제거하는 역활이있어서 추가함.. 차후 색상 추가 예정 */}
      <div className="hidden border-black"></div>
      <div className="hidden bg-tangerine5 text-tangerine5 border-tangerine5"></div>
      <div className="hidden bg-cyan border-cyan text-cyan"></div>
      <div className="hidden text-orange border-orange bg-orange"></div>
      <div className="hidden text-orange4 border-orange4 bg-orange4"></div>
      <div className="hidden border-orange5 bg-orange5"></div>
      <div className="hidden bg-red5 border-red5 text-red5"></div>
      <div className="hidden bg-purple0"></div>
      <div className="hidden bg-purple1"></div>
      <div className="hidden bg-purple2"></div>
      <div className="hidden bg-purple4 text-purple4"></div>
      <div className="hidden bg-purple5 border-purple5 text-purple5 checked:bg-purple5"></div>
      <div className="hidden bg-purple6"></div>
      <div className="hidden bg-gray0 border-gray0 text-gray0 xl:bg-gray0"></div>
      <div className="hidden bg-gray1 border-gray1 text-gray1 xl:bg-gray1"></div>
      <div className="hidden bg-gray2 border-gray2 text-gray2 xl:bg-gray1"></div>
      <div className="hidden bg-gray5 border-gray5 text-gray5"></div>
      <div className="hidden bg-gray7 border-gray7 text-gray7"></div>
      <div className="hidden bg-gray8 border-gray8 text-gray8"></div>
      <div className="hidden bg-gray9 border-gray9 text-gray9"></div>
      <div className="hidden bg-gray10 border-gray10 text-gray10"></div>
      <div className="hidden bg-gray11 border-gray11 text-gray11"></div>
      <div className="hidden bg-gray12 border-gray12 text-gray12"></div>
      <div className="hidden bg-skyblue border-skyblue text-skyblue bg-skyblue_5"></div>
      <div className="hidden bg-a_tangerine border-a_tangerine text-a_tangerine"></div>
      <div className="hidden text-title_h1 text-title_h2 text-title_h3 text-title_h4 text-title_h5 text-title_h6 xl:text-title_h1 xl:text-title_h2 xl:text-title_h3 xl:text-title_h5"></div>
      <div className="hidden text-content text-sub_title01 text-chips xl:text-content xl:text-content_rel xl:text-sub_title01"></div>
      <div className="hidden xl:text-button text-button2 text-button_text1"></div>
      <div className="hidden text-content_body1 text-content_body2 text-content_body3 text-content_body4 text-content_body5 xl:text-content_body2 xl:text-content_body3 xl:text-content_body4"></div>
      <div className="hidden text-caption1 text-caption2 text-caption3 text-caption4"></div>
    </Fragment>
  );
}

export default App;
