import e from 'express';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../store/hook';
import { openAlert } from '../store/features/modalSlice';
import { useMemo, useState } from 'react';
import React from 'react';
import { NavigationList } from './lmsBottomNavigationItems';
import { selectLmsLists } from '../store/features/lmsSlice';
import { courseIdDetermination } from '../api/utils/lms/ExtendSetBtnLink';

interface NavigationType {
  img: string;
  imgActive: string;
  title: string;
  path: string;
}
const LmsBottomNavigation = ({ classId }: { classId: string }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const lmsLists = useAppSelector(selectLmsLists);

  const list = React.useMemo(() => {
    return NavigationList(classId, courseIdDetermination(classId, lmsLists));
  }, [classId, lmsLists]);

  const path = useMemo(() => {
    const route = location.pathname.split('/');
    if (route.length > 3) {
      const root = route[3]; // ./lms/{classId}/{root}
      if (
        [
          'dashboard',
          'take-class',
          'signup-management',
          'class-setting',
          'notice-management',
        ].includes(root)
      ) {
        return './' + root;
      } else if (root === 'todo-management' && route.length > 4) {
        return './' + root + '/' + route[4];
      }
    }
  }, [location.pathname]);

  const handleNavigation = (_path: string) => {
    if (path === _path) return;

    if (_path === './class-setting') {
      dispatch(openAlert({ message: '준비 중인 기능입니다.' }));
      return;
    }

    navigate(_path);
  };

  return (
    <div
      className={`fixed grid grid-cols-${list.length} items-center left-0 z-10 w-full h-20 bg-white shadow-card`}
    >
      {list.map((navigation, idx) => (
        // <Link to={navigation.path + "/" + classId} key={idx}>
        <div
          className="flex flex-col items-center cursor-pointer"
          onClick={() => handleNavigation(navigation.path)}
          key={idx}
        >
          <img
            src={
              path === navigation.path ? navigation.imgActive : navigation.img
            }
            width={'40px'}
            height={'40px'}
            alt={navigation.title}
          />
          <div
            className={`text-sm font-normal ${
              path === navigation.path ? 'text-purple5' : 'text-gray7'
            }`}
          >
            {navigation.title}
          </div>
        </div>
        // </Link>
      ))}
    </div>
  );
};

export default LmsBottomNavigation;
