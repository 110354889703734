import dayjs, { Dayjs } from 'dayjs';

export type carouselItem = {
  img: string;
  mobile: string;
  title: string;
  link: string;
  type?: '_blank';
  date?: {
    start: Dayjs;
    end: Dayjs;
  };
};

export const IMAGE_LIST: carouselItem[] = [
  {
    img: 'images/home/banner/bannerD-24.10.07.jpg',
    mobile: 'images/home/banner/bannerM-24.10.07.jpg',
    title: '리뉴얼 홈페이지 배너- 10월 홈스쿨',
    link: 'https://blog.naver.com/jebs_jemm/223604438443',
    type: '_blank',
    date: {
      start: dayjs('2024-10-10'),
      end: dayjs('2024-10-23'),
    },
  },
  {
    img: 'images/home/banner/bannerD-24.09.12-2.jpg',
    mobile: 'images/home/banner/bannerM-24.09.12-2.jpg',
    title: '리뉴얼 홈페이지 배너- 김PD가간다-광명교회',
    link: 'https://youtu.be/Cqt7cDnZVNc?si=i9ontV0V1sNtOM3V',
    type: '_blank',
    date: {
      start: dayjs('2024-09-19'),
      end: dayjs('2024-10-31'),
    },
  },
  {
    img: 'images/home/banner/bannerD-24.08.19.jpg',
    mobile: 'images/home/banner/bannerM-24.08.19.jpg',
    title: '리뉴얼 홈페이지 배너- 영어로 그림전도 출간',
    link: '/shop/details/U9NHpfT92l_A4zTE',
  },
  {
    img: 'images/home/banner/bannerD-24.07.25-1.jpg',
    mobile: 'images/home/banner/bannerM-24.07.25-1.jpg',
    title: '리뉴얼 홈페이지 배너- 사도행전(변경)',
    link: 'shop/details/CoV_Dm3uYHgZR8x3',
  },
  {
    img: 'images/home/banner/bannerD1-24.06.11.jpg',
    mobile: 'images/home/banner/bannerM1-24.06.11.jpg',
    title: '리뉴얼 홈페이지 배너- 로마서',
    link: '/shop/details/bHYsQSuuInn9fG3b',
  },
];
