import { ToDoStatusEnum } from '../../enum';

export type parentType = {
  name: string;
  phone: string;
  parentType: string;
};

/**
 * @description 학생 정보
 */
export type studentType = {
  id: number;
  name: string;
  uid: string;
  birthday: Date;
  email: string;
  phone: string;
  address: string;
  detailAddress: string;
  zipCode: string;
  schoolName: string;
  schoolGroup: string;
  legacyJebsId: string;
  teacherId: string;
  classInfos: classInfo[];
  status: studentStatusType;
  createdAt: Date;
  updatedAt: Date;
  deletedAt?: Date;
  parents: parentType[];
};

export enum studentStatusType {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  PENDING = 'PENDING',
}

export type classInfo = {
  classGroup: classGroupType;
  classId: string;
  teacherId: string;
  studentStatus: studentStatusType;
};

export type classGroupType = {
  id: number;
  classId: string;
  name: string;
  description: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt?: Date;
};

export type MyLearningCourseType = {
  id: string;
  name: string;
  thumbnail: string;
  type: string;
};

export type myLearingType = {
  classId: string;
  id: string;
  isActive: boolean;
  name: string;
  teacherName: string;
  thumbnail: string;
  todoCompletionCount: null | number;
  todoCompletionRate: null | number;
  todoTotalCount: null | number;
};

export type myLearningInfoType = {
  course: MyLearningCourseType;
  myLearning: myLearingType;
};

export type myLearingInfoDetailType = {
  teacherName: string;
  course: MyLearningCourseType;
  notices: myLearningNoticeType[];
  todos: myLearningTodoType[];
};

export type myLearningNoticeType = {
  createdAt: Date;
  id: number;
  title: string;
  updateAt: Date;
};

export type myLearningTodoType = {
  id: number;
  legacyJebsId: string;
  link: string;
  name: string;
  remainDays: number;
  status: ToDoStatusEnum;
  toDate: Date;
  type: string;
  fromDate: Date;
};

export type studentGuardianType = {
  id: string;
  name: string;
  birthday: Date;
  gender: 'MALE' | 'FEMALE';
  countryCode: string;
  phone: string;
  agreementPersonalInfo: boolean;
};
export type studentProfileType = {
  birthday: string;
  classIds: string[];
  englishName: string;
  guardianInfo: studentGuardianType;
  name: string;
};

export type studentRecitationType = {
  failDate: Date | null;
  level: string;
  passDate: Date | null;
  scLesson: number;
  testDate: Date | null;
  title: string;
};

export type studentRecitationLevelType = {
  groupLevel: string;
  results: studentRecitationType[];
};

export type studentBadgeType = {
  name: string;
  description: string;
  imagePath: string;
  achieveDate: Date;
  sort: number;
};
