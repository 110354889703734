import { Pagination, Table } from 'antd';
import React, { useEffect, useMemo } from 'react';
import RecitationStudentColumns from './RecitationStudentColumn';
import { recitationResultType } from '../../../constants/types/lms-type';
import { getRecitationResult } from '../../../api/utils/lms/lmsApi';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { PaginationProps } from '../../../constants/types';
import RecitationStudentSearch from './RecitationStudentSearch';

const RecitationStudentList = () => {
  const { classId } = useParams<{ classId: string }>();
  const [params, setParams] = useSearchParams();
  const navigate = useNavigate();

  const [data, setData] = React.useState<recitationResultType[]>();
  const [pageInfo, setPageInfo] = React.useState<PaginationProps>({
    page: 1,
    pageSize: 10,
    total: 0,
    lastPage: 1,
  });
  const { page, pageSize, total, lastPage, searchOption, searchValue } =
    useMemo(() => {
      return {
        page: params.get('page') ? Number(params.get('page')) : 1,
        pageSize: params.get('pageSize') ? Number(params.get('pageSize')) : 10,
        total: params.get('total') ? Number(params.get('total')) : 0,
        lastPage: params.get('lastPage') ? Number(params.get('lastPage')) : 1,
        searchOption: params.get('searchOption')
          ? params.get('searchOption')
          : undefined,
        searchValue: params.get('searchValue')
          ? params.get('searchValue')
          : undefined,
      };
    }, [params]);

  const getData = () => {
    getRecitationResult(
      classId,
      { page, pageSize },
      searchOption,
      searchValue
    ).then((res) => {
      if (res.result.length === 0 && res.page.page > 1) {
        params.set('page', res.page.lastPage.toString());
        setParams(params);
        return;
      }
      setData(res.result);
      setPageInfo(res.page);
    });
  };

  useEffect(() => {
    getData();
  }, [page, pageSize, searchOption, searchValue]);

  return (
    <>
      <RecitationStudentSearch />
      <Table
        rowKey={(row) => row.user.id}
        columns={RecitationStudentColumns(pageInfo, navigate)}
        dataSource={data}
        size="small"
        pagination={false}
        scroll={{ x: 'max-content' }}
      />
      <Pagination
        current={pageInfo.page}
        pageSize={pageInfo.pageSize}
        total={pageInfo.total}
        onChange={(page, pageSize) => {
          params.set('page', page.toString());
          params.set('pageSize', pageSize.toString());
          setParams(params);
        }}
        className="mt-5 justify-center"
      />
    </>
  );
};

export default RecitationStudentList;
