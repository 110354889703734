import React, { useEffect, useState } from 'react';
import TextWinds from '../../../components/common/TextWinds';
import WhatIsCertification from './WhatIsCertification';
import IconAward from './IconAward';
import instance from '../../../api/axios/utils.ts/instance';
import { ApiResponse } from '../../../constants/types';
import {
  studentRecitationLevelType,
  studentRecitationType,
} from '../../../constants/types/student-type';
import { useAppDispatch } from '../../../store/hook';
import { openModal } from '../../../store/features/modalSlice';
import ModalRecitationCertificate from '../../../components/modal/ModalRecitationCertificate';
import dayjs from 'dayjs';

const StudentCertificatioinPage = () => {
  const [data, setData] = useState<studentRecitationLevelType[]>([]);

  const getRecitation = async () => {
    try {
      const response = await instance.get<
        ApiResponse<studentRecitationLevelType[]>
      >('v1/student/class/4uMfWRvh4rlVNSHY/recitation');
      return response.data.data;
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getRecitation().then((data) => {
      setData(data);
    });
  }, []);

  return (
    data.length > 0 && (
      <div className="bg-[#f3f0e7] py-16">
        <div className="w-full max-w-[1176px] m-auto px-5">
          <TextWinds type={'title_h1'} children={'암송인증제 북별 합격 현황'} />
          <WhatIsCertification />
          {data.map((level) => (
            <StudentCertificatioinLevel key={level.groupLevel} level={level} />
          ))}
        </div>
      </div>
    )
  );
};

const StudentCertificatioinLevel = ({
  level,
}: {
  level: studentRecitationLevelType;
}) => {
  const dispatch = useAppDispatch();
  const checkBadge = !level.results
    ?.map((book) => (book.passDate ? true : false))
    .includes(false);

  const openCertificateModal = () => {
    dispatch(
      openModal({
        body: (
          <ModalRecitationCertificate
            englishName={'ENGLISH NAME'}
            subject={'LEVEL 1' + level.groupLevel}
            date={new Date('2024-10-7')}
          />
        ),
      })
    );
  };
  return (
    <div className="mt-4">
      {/* <TextWinds type={'title_h3'} children={`Level ${level.groupLevel}`} /> */}
      <div className="flex gap-2 items-center">
        <TextWinds type="title_h3">LEVEL {level.groupLevel}</TextWinds>
        <img
          src={
            process.env.PUBLIC_URL +
            `/images/student/badge/recitation/LV${level.groupLevel}.png`
          }
          className={`w-5 h-5 ${checkBadge ? '' : 'filter grayscale'}`}
        />
        {/* {checkBadge && (
          <button
            onClick={openCertificateModal}
            className="text-sm text-skyblue border border-skyblue rounded-lg px-1 p-[2px] hover:bg-gray2"
          >
            인증서 <DocumentArrowDownIcon className="w-4 h-4 inline" />
          </button>
        )} */}
      </div>
      <div className="grid grid-cols-4 gap-4 mt-2">
        {level.results.map((book) => (
          <StudentCertificationBook key={book.title} book={book} />
        ))}
      </div>
    </div>
  );
};

const StudentCertificationBook = ({
  book,
}: {
  book: studentRecitationType;
}) => {
  const onoff = book.passDate ? true : false;
  // const onoff = true;

  return (
    <div className="flex flex-col">
      <div
        className={`w-full text-center shadow-card rounded-lg py-3 text-lg font-bold ${
          onoff ? 'bg-spotLilac text-white' : 'bg-gray-100 text-gray-500'
        }`}
        // onClick={() => {
        //   alert('TODO : 버튼인지 보는 용도인지 확인 필요');
        // }}
      >
        <div
          className={`flex flex-col sm:flex-row items-center justify-center gap-2`}
        >
          {onoff && <IconAward />}
          {book.title}
        </div>
      </div>

      <TextWinds
        type="content_body3"
        color="gray7"
        className="text-center mt-1"
      >
        {book.passDate
          ? '합격 : ' + dayjs(book.passDate).format('YYYY-MM-DD')
          : book.failDate
          ? '불합격 : ' + dayjs(book.failDate).format('YYYY-MM-DD')
          : book.testDate
          ? '연습 : ' + dayjs(book.testDate).format('YYYY-MM-DD')
          : ''}
      </TextWinds>
    </div>
  );
};

export default StudentCertificatioinPage;
